import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Loader from "../Loader";
import MyButton from "../MyButton";

function DeleteForm({ show, handleClose, handleSubmit, data, loading}) {
  const [input, setInput] = useState('');

  const handleCloseClick = () => {
    handleClose();
    setInput('');
  }
  
  const handleSubmitClick = (id) => () => {
    handleSubmit(id);
    setInput('');
  }

  if(!data) {
    return null;
  }

  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title className="text-dark">Delete {data.ticker}</Modal.Title>
    </Modal.Header>

    <Modal.Body className="pb-0">
      <p className="text-dark">
        <b className="text-dark">Amount:</b> {data.amount}
        <br/>
        <b className="text-dark">Price:</b> {Math.round((data.price / 10000) * 100) / 100}
        <br/>
        <b className="text-dark">Type:</b> {data.ordertype}
        <br/>
        <b className="text-dark">Date:</b> {(new Date(data.date)).toLocaleDateString()}
      </p>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="text-dark">Type 'delete'</Form.Label>
          <Form.Control className="text-dark" type="text" onChange={e => setInput(e.target.value)} value={input}/>
        </Form.Group>
      </Form>
    </Modal.Body>

    {loading? 
      <div className="d-flex flex-row justify-content-center align-items-center px-3 pb-3">
        <Loader white={false}/>
      </div>
    : 
      <div className="d-flex flex-row justify-content-between align-items-center px-3 pb-3">
        <MyButton className="mybutton-red" onClick={handleCloseClick}>Close</MyButton>
        <MyButton className="mybutton-blue" onClick={handleSubmitClick(data.orderid)} disabled={'delete' !== input}>Delete</MyButton>
      </div>
    }
  </Modal>;
}

export default DeleteForm;

import { useReducer } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import Loader from "../Loader";
import MyButton from "../MyButton";
import CreatableSelect from 'react-select/creatable';

const validator = ({ ticker, amount }) => !!amount && !!ticker;

const initialState = {
  ticker: '',
  amount: 0
};

function reducer(state, action) {
  if(action.type === 'update') {
    return {...state, [action.payload.field]: action.payload.data};
  }
  return {...initialState};
}

function StakingForm({ title, show, handleClose, handleSubmit, options, loading, error}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (field) => (e) => {
    const newValue = e.target.value;
    dispatch({type: 'update', payload: { field, data: newValue }});
  }

  const handleCloseClick = () => {
    dispatch({type: 'reset'});
    handleClose();
  }

  
  const convertThenSubmit = () => {
    const { amount, ...rest } = state;
    handleSubmit({
      amount: parseFloat(amount),
      ...rest
    });
  }

  return <Modal show={show} onHide={handleClose} className="dark-text">
    <Modal.Header closeButton>
      <Modal.Title >Add {title}</Modal.Title>
    </Modal.Header>

    <Modal.Body className="pb-0 my-modal-body">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Search for Ticker</Form.Label>
          <CreatableSelect isClearable on options={options} onChange={(option) => {
            dispatch({type: 'update', payload: { field: 'ticker', data: option?.value }})
          }}/>
        </Form.Group>
        
        <Form.Group className="mb-3">
          <Form.Label>Amount</Form.Label>
          <Form.Control type="number" onChange={handleChange('amount')} value={state.amount}/>
        </Form.Group>
      </Form>
    </Modal.Body>

    
    {error && <Alert className="text-center mx-3" variant='danger'>{error}</Alert>}

    {loading? 
      <div className="d-flex flex-row justify-content-center align-items-center px-3 pb-3">
        <Loader white={false}/>
      </div>
    : 
      <div className="d-flex flex-row justify-content-between align-items-center px-3 pb-3 white-text">
        <MyButton className="mybutton-red" onClick={handleCloseClick}>Close</MyButton>
        <MyButton className="mybutton-blue" onClick={convertThenSubmit} disabled={!validator(state)}>Save</MyButton>
      </div>
    }
  </Modal>;
}

export default StakingForm;

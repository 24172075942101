import { useState } from "react";
import { Col, Table } from "react-bootstrap";
import { convertBack } from "../../utils/generic";
import MyButton from "../MyButton";

const desc = (a,b) => a.date - b.date;
const asc = (a,b) => b.date - a.date;

const getBGType = (type) => {
  switch (type) {
    case 'buy':
      return 'bg-success';
    case 'sell':
      return 'bg-danger';
    case 'stake':
      return 'bg-warning';
  
    default:
      return 'bg-success';
  }
}

function Orders({ rows=[], startDelete }) {
  const [dateAsc, setDateAsc] = useState(false);
  const orders = rows?.map(({ date, ...rest }) => {
    return {
      date: new Date(date),
      ...rest
    };
  }).sort(dateAsc? asc : desc);
  return <Col className="border rounded mt-1 p-4">
    <h2 className="text-center mt-2 user-select-none">Orders</h2>
    <Table responsive>
      <thead>
        <tr>
          <th>Ticker</th>
          <th className="click user-select-none" onClick={() => setDateAsc(!dateAsc)}>
            Date {dateAsc? <>&#x25b4;</> : <>&#x25be;</>}
          </th>
          <th>Price</th>
          <th>Amount</th>
          <th>Cost</th>
          <th>Fee</th>
          <th>OrderType</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map(order => {
          const date = new Date(order.date);
          const price = order.price / 10000;
          return <tr key={order.orderid} className={getBGType(order.ordertype)}>
            <td>{order.ticker}</td>
            <td>{date.toLocaleDateString()}</td>
            <td>&euro;{price.toFixed(2)}</td>
            <td>{order.amount}</td>
            <td>&euro;{(order.amount * price).toFixed(2)}</td>
            <td>&euro;{convertBack(order.fee)}</td>
            <td>{order.ordertype}</td>
            <td>
              <MyButton className='mybutton-red' onClick={() => startDelete(order)}>
                remove
              </MyButton>
            </td>
          </tr>;
        })}
      </tbody>
    </Table>
  </Col>;
}

export default Orders;

import { Row, Col } from "react-bootstrap";
import Loader from "../Loader";
import MyButton from "../MyButton";

function Users({ users, error, openEdit, openDelete}) {
  if(error) {
    return <div>
      <h4>Something went wrong while retrieving users, please try again later.</h4>
    </div>
  }

  return (
    <div>
      {users?.map(({id, username, isAdmin}) => {
        return id !== 1 && <Row key={id} className="d-flex flex-row m-1 p-1 border-bottom">
          <Col xs="2" className="d-flex flex-column justify-content-center align-items-start border-right">ID: {id}</Col>
          <Col xs="5" className="d-flex flex-column justify-content-center align-items-center text-center">{username}</Col>
          <Col xs="1" className="d-flex flex-column justify-content-center align-items-center">{isAdmin? 'admin' : ''}</Col>
          <Col xs="4">
            <div className="d-flex flex-row justify-content-around align-items-center">
              <MyButton className="mybutton-blue" onClick={openEdit({id, username, isAdmin, password: ''})}>edit</MyButton>
              <MyButton className="mybutton-red" onClick={openDelete({id, username})}>remove</MyButton>
            </div>
          </Col>
        </Row>;
      }) || <Loader />}
    </div>
  );
}

export default Users;

import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getLeaderBoard, getStats } from "../api";
import Header from "../components/home/Header";
import Information from "../components/home/Information";
import Leaderboard from "../components/home/Leaderboard";

function Home({ username, me }) {
  const [stats, setStats] = useState(null);
  const [leaderBoard, setLeaderboard] = useState(null);

  useEffect(() => {
    getLeaderBoard().then(setLeaderboard).catch(console.log);
    getStats().then(setStats).catch(console.log);
  }, []);

  useEffect(() => {
    const inter = setInterval(() => {
      getLeaderBoard().then(setLeaderboard).catch(console.log);
    }, 30000)
    return () => {
      clearInterval(inter);
    }
  }, []);

  return <div>
    <Row>
      <Col lg="6">
        <Header me={me} isAnouk={username === 'Anouk'}/>
        <Information data={stats} balance={me?.totalBalance} fees={me?.fees}/>
      </Col>

      <Col lg="6" className="d-flex flex-column">
        <Leaderboard rows={leaderBoard}/>
      </Col>
    </Row>
  </div>;
}

export default Home;

import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getRandomStats } from "../api";
import StatList from "../components/stats/StatList";
import StatsDivs from "../components/stats/StatsDiv";
import { convertBack } from "../utils/generic";

function Stats() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    getRandomStats().then(setStats).catch(console.log);
  }, []);

  if(!stats) {
    return <span>loading stats...</span>;
  }

  return <div>
    <Row>
      <Col lg="3" className="mt-1">
        <StatsDivs>
          <h2 className="text-center mt-2">Biggest</h2>
          <p><b>Gain:</b> &euro;{stats.biggestGain}</p>
          <p><b>Loss:</b> &euro;{stats.biggestLoss}</p>
        </StatsDivs>
      </Col>
      
      <Col lg="3" className="mt-1">
        <StatsDivs>
          <h2 className="text-center mt-2">Most Traded</h2>
          <p><b>Crypto</b></p>
          <StatList stats={stats.mostTradedCrypto}/>
          <p><b>Stock</b></p>
          <StatList stats={stats.mostTradedStock}/>
        </StatsDivs>
      </Col>
      
      <Col lg="3" className="mt-1">
        <StatsDivs>
          <h2 className="text-center mt-2 ">Least Traded</h2>
          <p><b>Crypto</b></p>
          <StatList stats={stats.leastTradedCrypto}/>
          <p><b>Stock</b></p>
          <StatList stats={stats.leastTradedStock}/>
        </StatsDivs>
      </Col>
      
      <Col lg="3" className="mt-1">
        <StatsDivs>
          <h2 className="text-center mt-2">Most Trades</h2>
          {Object.entries(stats.mostTradingUser).map(([name, trades]) => {
            return <p className="text-center" key={name}><b>{name}:</b> {trades}</p>;
          })}
        </StatsDivs>
      </Col>
      
      <Col lg="3" className="mt-1">
        <StatsDivs>
          <h2 className="text-center mt-2">Total</h2>
          <p><b>Fees:</b> &euro;{convertBack(stats.totalFees)}</p>
          <p><b>Trades:</b> {stats.totalTrades}</p>
        </StatsDivs>
      </Col>
    </Row>
  </div>;
}

export default Stats;

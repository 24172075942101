import { useEffect, useState } from "react";
import { createUser, deleteUser, getUsers, updateUser } from "../api";
import DeleteForm from "../components/admin/DeleteForm";
import UserForm from "../components/admin/UserForm";
import Users from "../components/admin/Users";
import MyButton from "../components/MyButton";

function Admin() {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [formData, setFormData] = useState({});

  const refreshList = () => {
    setLoading(false);
    resetForm();
    getUsers().then(setUsers).catch(setError);
  }

  useEffect(() => {
    refreshList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    setFormData({});
  };

  const openAdd = () => {
    setFormData({ isAdmin: false });
    setShowAddForm(true);
  }

  const closeAdd = () => {
    setShowAddForm(false);
    resetForm();
  };

  const openEdit = (data) => () => {
    setFormData(data);
    setShowEditForm(true);
  }
  
  const closeEdit = () => {
    setShowEditForm(false);
    resetForm();
  };
  
  const openDelete = (data) => () => {
    setFormData(data);
    setShowDeleteForm(true);
  }
  
  const closeDelete = () => {
    setShowDeleteForm(false);
    resetForm();
  };

  const handleSet = (field) => (e) => {
    const newValue = field === 'isAdmin'? e.target.checked : e.target.value;
    setFormData({...formData, [field]: newValue});
  };

  const submitAdd = () => {
    setLoading(true);
    createUser(formData).finally(() => {
      setShowAddForm(false);
      refreshList();
    })
  };

  const submitEdit = () => {
    setLoading(true);

    const { id, isAdmin, username, password } = formData;
  
    const newData = { isadmin: isAdmin, username };

    if(password) {
      newData.password = password;
    }

    updateUser(id, newData).finally(() => {
      setShowEditForm(false);
      refreshList();
    })
  };
  
  const submitDelete = () => {
    setLoading(true);
    deleteUser(formData.id).finally(() => {
      setShowDeleteForm(false);
      refreshList();
    })
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center mt-4">
        <h1>Users</h1>
        <MyButton className="mybutton-blue" onClick={openAdd}>
          Add User
        </MyButton>
      </div>

      <UserForm key="add"
        title="Add user"
        show={showAddForm}
        handleClose={closeAdd}
        data={formData}
        setData={handleSet}
        handleSubmit={submitAdd}
        loading={loading}
      />

      <UserForm key="edit"
        title="Edit user"
        show={showEditForm}
        handleClose={closeEdit}
        data={formData}
        setData={handleSet}
        handleSubmit={submitEdit}
        loading={loading}
      />
      
      <DeleteForm
        show={showDeleteForm}
        handleClose={closeDelete}
        data={formData}
        handleSubmit={submitDelete}
        loading={loading}
      />

      <Users users={users} error={error} openEdit={openEdit} openDelete={openDelete} submitEdit={submitEdit} />
    </div>
  );
}

export default Admin;

import { Col } from "react-bootstrap";

function Header({ isAnouk }) {
  return <Col>
    {isAnouk?
      <p className="text-left disney-header">Disney Casino</p>
    :
      <p className="text-left mega-header">Casino</p>
    } 
  </Col>;
}

export default Header;

import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { login } from '../api';
import Loader from '../components/Loader';
import MyButton from '../components/MyButton';

const messages = [
  'Keep calm and try again.',
  'One more wrong attempt and AMC defaults...',
  'Maybe type it again... but with more anger this time!',
  'So close, keep trying!!!',
  'Don\'t you dare type the wrong password again!',
  'Set aside your beer and try again...slowly...',
  'Maybe you should get checked for alzheimers or something...',
  'Am I a joke to you?!',
  'Maybe typing with only one finger will help...',
  'Ever heard of keepass?'
]

function Login({ handleAuth }) {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });
  const [failed, setFailed] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => { 
    e.preventDefault();
    setFailed(false);
    setLoggingIn(true);
    login(credentials)
      .then(() => {
        localStorage.setItem('isLoggedin', 'true');
        handleAuth();
      })
      .catch(() => {
        setMessage(messages[Math.floor(Math.random() * 10)]);
        setLoggingIn(false);
        setFailed(true);
      });
  };

  const handleCredentials = (e) => {
    setCredentials({...credentials, [e.target.name]: e.target.value});
  };

  return (
    <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
      <h1 className="user-select-none">Login</h1>
      
      {failed && <span className="mb-3 text-center">Login failed. {message}</span>}

      <Form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control className="text-dark" type="text" placeholder="username" name="username" onChange={handleCredentials}/>
          <Form.Control className="mt-2 text-dark" type="password" placeholder="password" name="password" onChange={handleCredentials}/>
        </Form.Group>
        {
        loggingIn?
          <Loader />
        : 
          <MyButton className="mt-2 mybutton-blue" onClick={handleSubmit} type="submit">
            Login
          </MyButton>
        }
      </Form>

    </div>
  );
}

export default Login;

import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { addCrypto, addStock, addStaking, deleteAsset, GetCryptoTickers, getOrders, GetStockTickers, deleteStaking } from "../api";
import DeleteForm from "../components/admin/DeleteForm";
import AssetsPie from "../components/assets/AssetsPie";
import Orders from "../components/assets/Orders";
import StonkForm from "../components/home/StonkForm";
import StakingForm from "../components/home/StakingForm";
import MyButton from "../components/MyButton";

const STONKFORM = 'STONKFORM';
const CRYPTOFORM = 'CRYPTOFORM';
const STAKINGFORM = 'STAKINGFORM';
const DELETEFORM = 'DELETEFORM';

function Assets({ me }) {
  const [stockOptions, setStockOptions] = useState(null);
  const [cryptoOptions, setCryptoOptions] = useState(null);
  const [orders, setOrders] = useState(null);
  const [form, setForm] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getOrders().then(setOrders).catch(console.log);
    GetStockTickers().then(setStockOptions).catch(console.log);
    GetCryptoTickers().then(setCryptoOptions).catch(console.log);
  }, []);

  const handleSubmit = (apiFunc) => (data) => {
    setError(null);
    setLoading(true);
    apiFunc(data).then(() => {
      setForm(null);
      getOrders().then(setOrders).catch(console.log);
    }).catch(err => {
      if(err?.response?.data) {
        setError(err?.response?.data);
      }else {
        setError('Something went wrong, please try again');
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleClose = () => {
    setError(null);
    setForm(null);
  }

  const handleDelete = async (id) => {
    setError(null);
    setLoading(true);
    try {
      if(selected.ordertype === 'stake') {
        await deleteStaking(id);
      }else {
        await deleteAsset(id);
      }
    } catch (err) {
      if(err?.response?.data) {
        setError(err?.response?.data);
      }else {
        setError('Something went wrong, please try again');
      }
    }
    getOrders().then(setOrders).catch(console.log);
    setForm(null);
    setLoading(false);
  };

  const startDelete = (data) => {
    setSelected(data);
    setForm(DELETEFORM);
  }

  const openForm = (type) => () => {
    setForm(type);
  }

  return <div>
    <StonkForm key="stonk" title="Stock" show={form === STONKFORM} handleClose={handleClose} handleSubmit={handleSubmit(addStock)} options={stockOptions} loading={loading} error={error}/>
    <StonkForm key="crypto" title="Crypto" show={form === CRYPTOFORM} handleClose={handleClose} handleSubmit={handleSubmit(addCrypto)} options={cryptoOptions} loading={loading} error={error}/>
    <StakingForm key="staking" title="Staking" show={form === STAKINGFORM} handleClose={handleClose} handleSubmit={handleSubmit(addStaking)} options={cryptoOptions} loading={loading} error={error}/>
    
    <DeleteForm show={form === DELETEFORM} handleClose={handleClose} handleSubmit={handleDelete} data={selected} loading={loading}/>
    
    <Row>
      <Col lg="4">
        <AssetsPie assets={me.assets}/>
      </Col>
    </Row>
    <div className="d-flex flex-column py-2">
      <div className="d-flex flex-row justify-content-around align-items-center">
        <MyButton className='mybutton-blue' onClick={openForm(CRYPTOFORM)}>
          add cypto
        </MyButton>
        <MyButton className='mybutton-blue' onClick={openForm(STAKINGFORM)}>
          add staking
        </MyButton>
        <MyButton className='mybutton-blue' onClick={openForm(STONKFORM)}>
          add stock
        </MyButton>
      </div>
    </div>
    <Orders rows={orders} startDelete={startDelete}/>
  </div>;
}

export default Assets;

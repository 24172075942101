import { Form, Modal } from "react-bootstrap";
import Loader from "../Loader";
import MyButton from "../MyButton";

function UserForm({ title, show, handleClose, handleSubmit, data: { username, isAdmin=false, password}, setData, loading}) {
  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title className="text-dark">{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body className="pb-0">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="text-dark">Username</Form.Label>
          <Form.Control className="text-dark" type="text" placeholder="username" onChange={setData('username')} value={username || ''} 
            disabled={title === 'Edit user'}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="text-dark">Password</Form.Label>
          <Form.Control className="text-dark" type="password" placeholder="Password" onChange={setData('password')} value={password || ''}/>
        </Form.Group> 

        <Form.Group className="mb-3">
          <Form.Label className="text-dark">Admin</Form.Label>
          <Form.Check type="checkbox" onChange={setData('isAdmin')} checked={isAdmin}/>
        </Form.Group>
      </Form>
    </Modal.Body>

    {loading? 
      <div className="d-flex flex-row justify-content-center align-items-center px-3 pb-3">
        <Loader white={false}/>
      </div>
    : 
    <div className="d-flex flex-row justify-content-between align-items-center px-3 pb-3">
        <MyButton className="mybutton-red" onClick={handleClose}>Close</MyButton>
        <MyButton className="mybutton-blue" onClick={handleSubmit} disabled={title === 'Add user' && !password}>Save</MyButton>
      </div>
    }
  </Modal>;
}

export default UserForm;

import { useState } from 'react';
import { Container } from 'react-bootstrap';
import Home from './Home';
import Navigation from './Navigation';
import '../css/app.css';
import { logout } from '../api';
import Admin from './Admin';
import Assets from './Assets';
import Stats from './Stats';

function UserView({ setLoggedin, userInfo, me }) {
  const [view, setView] = useState('home');

  const handleLogout = () => {
    logout();
    setLoggedin(false);
    localStorage.removeItem('isLoggedin');
  };

  let Component;

  switch (view) {
    case 'home':
      Component = <Home username={userInfo?.username} me={me} />;
      break;
    case 'stats':
      Component = <Stats />;
      break;
    case 'assets':
      Component = <Assets me={me} />;
      break;
    case 'admin':
      Component = <Admin />;
      break;
    default:
      Component = <Home />;
      break;
  };

  return <Container fluid="md">
    <Navigation setView={setView} handleLogout={handleLogout} isAdmin={userInfo.isadmin} />
    {Component}
  </Container>;
}

export default UserView;

function Navigation({ setView, handleLogout, isAdmin }) {
  const hanldeSet = newView => () => setView(newView);
  return <div className="d-flex flex-row justify-content-around align-items-center py-2 border-bottom user-select-none">
    <span className="click" onClick={hanldeSet('home')}>Home</span>
    <span className="click" onClick={hanldeSet('stats')}>Stats</span>
    <span className="click" onClick={hanldeSet('assets')}>Assets</span>
    {isAdmin && <span className="click" onClick={hanldeSet('admin')}>Admin</span>}
    <span className="click" onClick={handleLogout}>Logout</span>
  </div>;
}

export default Navigation;

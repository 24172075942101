import { useState } from "react";
import { Col, Table } from "react-bootstrap";

function Leaderboard({ rows=[] }) {
  const [losers, setLosers] = useState(false);
  const array = losers? rows?.sort((a, b) => a.totalBalance - b.totalBalance) : rows?.sort((a, b) => b.totalBalance - a.totalBalance);
  return <Col className="border rounded mt-4 p-2">
    <h2 className="text-center mt-2 user-select-none" onClick={() => setLosers(!losers)}>{losers? 'Loserboard' : 'Leaderboard'}</h2>
    <Table responsive>
      <thead>
        <tr>
          <th>Place</th>
          <th>Name</th>
          <th>Investing</th>
          <th>Gains</th>
        </tr>
      </thead>
      <tbody>
        {array?.map((user, index) => {
          const balance = (user.totalBalance / 10000).toFixed(2);
          return <tr key={index}>
            <td>{index + 1}</td>
            <td>{user.username}</td>
            <td>{balance}</td>
            <td>{(balance / 1000 * 100 - 100).toFixed(2)}%</td>
          </tr>;
        })}
      </tbody>
    </Table>
  </Col>;
}

export default Leaderboard;

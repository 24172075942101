import axios from "axios";

const getData = res => res.data;

// baseURL: "https://casino.harmfarm.nl/API/v1",

export const api = axios.create({
  baseURL: "/API/v1",
  withCredentials: true
});

export const auth = () => api.get('/Auth/Auth').then(getData);

export const login = (credentials) => api.post('/Auth/login', credentials);

export const logout = () => api.get('/Auth/logout');

// Admin
export const getUsers = () => api.get('/Admin/GetUsers').then(getData);

export const createUser = (data) => api.post('/Admin/CreateUser', data).then(getData);

export const updateUser = (id, data) => api.put(`/Admin/UpdateUser/${encodeURIComponent(id)}`, data).then(getData);

export const deleteUser = (id) => api.delete(`/Admin/DeleteUser/${encodeURIComponent(id)}`).then(getData);

// User
export const getMe = () => api.get('/Trade/Me').then(getData);

export const getOrders = () => api.get('/Trade/GetOrders').then(getData);
export const getStats = () => api.get('/Trade/GetStats').then(getData);

export const addStock = (data) => api.post('/Trade/Stock', data).then(getData);
export const addCrypto = (data) => api.post('/Trade/Crypto', data).then(getData);
export const addStaking = (data) => api.post('/Trade/Staking', data).then(getData);

export const deleteAsset = (id) => api.delete(`/Trade/Trade/${id}`).then(getData);
export const deleteStaking = (id) => api.delete(`/Trade/Staking/${id}`).then(getData);

// returns  [{username, Totalbalance, [Assets]}]
export const getLeaderBoard = () => api.get('Trade/GetLeaderboard').then(getData);

//export const addStock = () => (data) => api.post('/Trade/Stock').then(getData);

const convertTickers = res => res?.data?.map(({ value, label }) => ({ value, label: `${value} - ${label}` })) || [];
export const GetCryptoTickers = () => api.get('/Trade/GetCryptoTickers').then(convertTickers);
export const GetStockTickers = () => api.get('/Trade/GetStockTickers').then(convertTickers);

export const getRandomStats = () => api.get('/Stats/GetRandomStats').then(getData);

// eslint-disable-next-line no-unused-vars
import { Chart } from 'chart.js/auto';
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";

const options = [
  {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgba(0, 0, 0, 1)'
  },
  {
    backgroundColor: 'rgba(230, 159, 0, 1)',
    borderColor: 'rgba(230, 159, 0, 1)'
  },
  {
    backgroundColor: 'rgba(86, 180, 233, 1)',
    borderColor: 'rgba(86, 180, 233, 1)'
  },
  {
    backgroundColor: 'rgba(213, 94, 0, 1)',
    borderColor: 'rgba(213, 94, 0, 1)'
  },
  {
    backgroundColor: 'rgba(255, 0, 0, 1)',
    borderColor: 'rgba(255, 0, 0, 1)'
  },
  {
    backgroundColor: 'rgba(0, 255, 0, 1)',
    borderColor: 'rgba(0, 255, 0, 1)'
  },
  {
    backgroundColor: 'rgba(0, 0, 255, 1)',
    borderColor: 'rgba(0, 0, 255, 1)'
  },
  {
    backgroundColor: 'rgba(240, 228, 65, 1)',
    borderColor: 'rgba(240, 228, 65, 1)'
  },
  {
    backgroundColor: 'rgba(0, 114, 178, 1)',
    borderColor: 'rgba(0, 114, 178, 1)'
  }
]

function Information({ data, balance, fees }) {
  return <Col className="border rounded p-3 white-color dark-text">
    <h4 className="text-center font-weight-bold">Gain % over time</h4>
    <div className="chart-wrapper">
      {data && <Line
        options={{
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.4
            }
          },
          borderWidth: 2
        }}
        datasetIdKey='id'
        data={{
          labels: data.dates,
          datasets: data.statsusers.map((su, index) => ({
            id: su.userId,
            label: su.username,
            data: su.listOfPercentages,
            ...options[index],
            pointRadius: 0
          }))
        }}
      />}
    </div>
    {<p className="m-0 pt-4">
      <b className="text-dark">Balance: </b> &euro;{(balance / 10000).toFixed(2)}
      <br/>
      <b className="text-dark">Fees paid: </b> &euro;{(fees / 10000).toFixed(2)}
    </p>}
  </Col>;
}

export default Information;

// eslint-disable-next-line no-unused-vars
import { Chart } from 'chart.js/auto';
import { Col } from "react-bootstrap";
import { Pie } from "react-chartjs-2";

const options = [
  {
    backgroundColor: 'rgba(230, 159, 0, 1)',
    borderColor: 'rgba(230, 159, 0, 1)'
  },
  {
    backgroundColor: 'rgba(86, 180, 233, 1)',
    borderColor: 'rgba(86, 180, 233, 1)'
  },
  {
    backgroundColor: 'rgba(213, 94, 0, 1)',
    borderColor: 'rgba(213, 94, 0, 1)'
  },
  {
    backgroundColor: 'rgba(255, 0, 0, 1)',
    borderColor: 'rgba(255, 0, 0, 1)'
  },
  {
    backgroundColor: 'rgba(0, 255, 0, 1)',
    borderColor: 'rgba(0, 255, 0, 1)'
  },
  {
    backgroundColor: 'rgba(0, 0, 255, 1)',
    borderColor: 'rgba(0, 0, 255, 1)'
  },
  {
    backgroundColor: 'rgba(240, 228, 65, 1)',
    borderColor: 'rgba(240, 228, 65, 1)'
  },
  {
    backgroundColor: 'rgba(0, 114, 178, 1)',
    borderColor: 'rgba(0, 114, 178, 1)'
  },
  {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgba(0, 0, 0, 1)'
  }
]

function AssetsPie({ assets }) {
  return <Col className="border rounded p-3 white-color dark-text mt-2">
    <h4 className="text-center font-weight-bold">Asset division %</h4>
    {assets && <Pie
      datasetIdKey='id'
      data={{
        labels: assets.map(ass => `${ass.assetTicker}: ${ass.amount}`),
        datasets: [
          {
            backgroundColor: assets.map((o, i) => options[i].backgroundColor),
            borderColor: assets.map((o, i) => options[i].borderColor),
            borderWidth: 2,
            data: assets.map(ass => (ass.amount * ass.assetPrice) / 10000),
            label: [150, 150, 150]
          }
        ]
      }}
    />}
  </Col>;
}

export default AssetsPie;

import { useEffect, useState } from 'react';
import { auth, getMe } from './api';
import Login from './containers/Login';
import UserView from './containers/UserView';
import './css/app.css';

function App() {
  const [me, setMe] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loggedin, setLoggedin] = useState(false);

  const handleAuth = () => {
    auth().then(data => {
      setUserInfo(data);
      getMe().then(setMe).catch(console.log);
      setLoggedin(true);
    }).catch(() => {
      setLoggedin(false);
      localStorage.removeItem('isLoggedin');
    })
  }

  useEffect(() => {
    if(localStorage.getItem('isLoggedin')) {
      handleAuth();
    }
  }, []);

  return <div className="App">
    {loggedin? <UserView setLoggedin={setLoggedin} userInfo={userInfo} me={me}/> : <Login setLoggedin={setLoggedin} handleAuth={handleAuth}/>}
  </div>;
}

export default App;
